import React from 'react';
import Layout from '../../components/Layout';

export default class ThanksPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Bedankt!</h1>
              <p>Ik probeer zo snel mogelijk te reageren op uw verzoek</p>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
};
